@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.App {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 16pt;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  vertical-align: top;
}


input {
  font-size: 16pt;
  padding: 5px;
  margin: 5px;
}

button {
  font-size: 16pt;
  padding: 5px;
  margin: 5px;
}

.results ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.results li {
  margin: 0;
  padding: 0;
}

.results th {
  background-color: #A0393B; /* POL red */
  padding: 10px;
  color: white;
}

.results td {
  vertical-align: top;
  padding: 10px 0px 10px 0px;
  margin: 0;
}

.results table tr:nth-child(odd) {
  background-color: #f2f2f2; 
}

.results table tr:nth-child(even) {
  background-color: #ffffff; 
}

.page-list {
  max-width: 150px; 
  overflow: auto; 
  white-space: nowrap; 
}

.clickable-page {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.modal {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  z-index: 1000; 
  text-align: left;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  width: 90%; 
  max-width: 800px; 
  max-height: 80vh; 
  overflow-y: auto;
}

.highlight {
  background-color: yellow;
}

.close {
  float: right;
  font-size: 3rem;
  font-weight: bold;
  cursor: pointer;
  color: red;
}

.modal-content p {
  padding: 0;
}